import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useContentWriterInformation } from 'src/hooks/useContentWriterInformation'

import { Avatar } from '../global/Avatar/Avatar'
import { HtmlContentViewer } from '../global/HtmlContentViewer/HtmlContentViewer'
import { PremiumContentIcon } from '../global/PremiumContentIcon/PremiumContentIcon'
import { InsightCardTags } from './InsightCardTags'
interface InsightCardsProps {
  className?: string
  insightItem: any
  key?: number
  openTooltipCardId?: string | null
  setOpenTooltipCardId: (id: string | null) => void
}

export const InsightCards: React.FC<InsightCardsProps> = ({ insightItem, openTooltipCardId, setOpenTooltipCardId }) => {
  const info: any = useContentWriterInformation(insightItem?.writer)
  const { logo } = info ?? {}

  return (
    <div className="insight-cards rounded-lg p-[24px]">
      <Link href={`/contents/${insightItem?.slug}`} passHref>
        <div className="avtar-div  rounded-lg mb-[20px] relative">
          {insightItem?.defaultImage ? (
            <Image
              src={insightItem?.defaultImage}
              alt=""
              className="rounded-lg object-fill w-full"
              priority={true}
              layout="fill"
              quality={90}
            />
          ) : (
            <PremiumContentIcon
              contentType={insightItem?.contentType}
              size="full"
              defaultImage={insightItem?.defaultImage}
            />
          )}
        </div>
        {insightItem?.title && (
          <h3 title={insightItem?.title} className="title line-clamp-2 mb-[8px] h-[3.6rem]">
            {insightItem?.title}
          </h3>
        )}
      </Link>
      <div className="flex flex-wrap gap-2 mb-[8px] h-[24px]">
        {insightItem?.subjectMatters && (
          <InsightCardTags
            cardId={insightItem?.id}
            tagItems={insightItem?.subjectMatters}
            openTooltipCardId={openTooltipCardId}
            setOpenTooltipCardId={setOpenTooltipCardId}
          />
        )}
      </div>
      <Link href={`/contents/${insightItem?.slug}`} passHref>
        <div className="text-sm mb-2 font-semibold text-gray-800 flex items-center space-x-2 h-[32px]">
          {insightItem?.writer?.name ? (
            <>
              <Avatar
                size="27px"
                initialsTextSize="12px"
                url={insightItem?.writer?.avatar || logo || ''}
                name={insightItem?.writer?.name}
                defaultMode="initials"
                border={insightItem?.writer?.avatar ? 'circle' : 'no-border'}
                variant="turquoise"
                className={'border-none'}
              />

              <span className="writer-name">{insightItem?.writer?.name}</span>
            </>
          ) : (
            <div className="h-full" />
          )}
        </div>

        <p className="published mb-2 h-20px">
          {insightItem?.publishedTime ? `Published ${moment(insightItem?.publishedTime).format('YYYY-MM-DD')}` : ''}
        </p>
        <HtmlContentViewer
          className="description line-clamp-3 overflow-hidden html-show w-full h-[72px]"
          html={insightItem?.snippet || ''}
        />
      </Link>
    </div>
  )
}
