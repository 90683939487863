import Image from 'next/image'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { LinkWithOrigin } from 'src/components/LinkWithOrigin/LinkWithOrigin'
import { ISuggest } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

interface ProductAutosuggestItemProps {
  data: ISuggest
  search: string
  increaseSolutionCountAsync: () => void
  showAsText?: boolean | null
}

export const ProductAutosuggestItem: React.FC<ProductAutosuggestItemProps> = ({
  data,
  search,
  increaseSolutionCountAsync,
  showAsText = false,
}) => {
  const logo = useDefaultLogo(data.logo)

  return (
    <>
      {showAsText ? (
        <div className="w-full flex bg-white hover:bg-gray-400 items-center px-8">
          <Image
            className="border border-solid border-mist rounded-[.1875rem]"
            src={logo}
            alt="logo"
            width={20}
            height={20}
            priority
          />
          <Highlighter
            className="text-textdark font-primary font-normal text-[.875rem] leading-7 ml-3"
            highlightClassName="font-bold"
            searchWords={[search]}
            textToHighlight={data.keyword}
          />
        </div>
      ) : (
        <LinkWithOrigin
          toUrl={data.slug}
          className="flex w-full relative"
          increaseSolutionCountAsync={increaseSolutionCountAsync}
        >
          <div className="w-full flex bg-white hover:bg-gray-400 cursor-pointer items-center px-8">
            <Image
              className="border border-solid border-mist rounded-[.1875rem]"
              src={logo}
              alt="logo"
              width={20}
              height={20}
              priority
            />
            <Highlighter
              className="text-textdark font-primary font-normal text-[.875rem] leading-7 ml-3"
              highlightClassName="font-bold"
              searchWords={[search]}
              textToHighlight={data.keyword}
            />
          </div>
        </LinkWithOrigin>
      )}
    </>
  )
}
