import React from 'react'
interface TooltipModelProps {
  tags?: string[]
}
const TooltipModel: React.FC<TooltipModelProps> = ({ tags }) => {
  if (tags && tags.length) {
    return (
      <div className="custom_tooltip insight-cards z-50">
        <div className="tooltip_inner_wrap relative">
          <ul>
            {tags &&
              tags.map((item, index) => (
                <li key={index + 'li'}>
                  <span
                    key={index + 'span'}
                    className="badges bg-gray-200 px-2 py-1 rounded-full text-sm font-semibold"
                  >
                    {item}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }
  return ''
}

export default TooltipModel
