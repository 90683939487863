import { ContentDataFragment, isContentItem, isContentListItemFragment } from './ContentDataFragment'

export const getContentDefaultImageUrl = (content: ContentDataFragment): string | null => {
  if (isContentListItemFragment(content)) {
    return content.attributes?.defaultImage?.data && content.attributes?.defaultImage?.data.length > 0
      ? content.attributes?.defaultImage?.data[0].attributes?.url || null
      : null
  }
  if (isContentItem(content)) {
    return content.defaultImage || null
  }
  return null
}
