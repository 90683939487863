import FingerprintJS from '@fingerprintjs/fingerprintjs'
import CryptoJS from 'crypto-js'
import { get, set } from 'idb-keyval'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const COOKIE_FINGERPRINT_KEY = 'fingerprint_id'
const STORAGE_FINGERPRINT_KEY = 'ufid'
const INDEX_FINGERPRINT_KEY = 'uid'
const SECRET_KEY = 'fingerprint_encoding_decoding' // Change this in production!

// Encrypt function
const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, SECRET_KEY).toString()
}

// Decrypt function
const decrypt = (ciphertext: string): string | null => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY)
    return bytes.toString(CryptoJS.enc.Utf8) || null
  } catch (error) {
    return null
  }
}

// Function to get fingerprint as a Promise
export const getFingerprint = async (): Promise<string | null> => {
  //  Step 1: Check Cookies
  let storedFingerprint = Cookies.get(COOKIE_FINGERPRINT_KEY) || null
  if (storedFingerprint) {
    // console.log(`data present in Cookies ${storedFingerprint}`)
    const decryptedId = decrypt(storedFingerprint)
    if (decryptedId) return decryptedId
  }

  // Step 2: Check LocalStorage
  storedFingerprint = localStorage.getItem(STORAGE_FINGERPRINT_KEY) || null
  if (storedFingerprint) {
    // console.log(`data present in LocalStorage ${storedFingerprint}`)
    const decryptedId = decrypt(storedFingerprint)
    if (decryptedId) {
      // console.log(`setting Data in Cookies ${storedFingerprint}`)
      Cookies.set(COOKIE_FINGERPRINT_KEY, storedFingerprint) // Save to cookies
      return decryptedId
    }
  }

  // Step 3: Check IndexedDB
  const indexedDBFingerprint = (await get(INDEX_FINGERPRINT_KEY)) || null
  if (indexedDBFingerprint) {
    // console.log(`data present in IndexedDB ${indexedDBFingerprint}`)
    const decryptedId = decrypt(indexedDBFingerprint)
    if (decryptedId) {
      // console.log(`setting Data in Cookies ${indexedDBFingerprint}`)
      Cookies.set(COOKIE_FINGERPRINT_KEY, indexedDBFingerprint) // Save to cookies
      // console.log(`setting Data in LocalStorage ${indexedDBFingerprint}`)
      localStorage.setItem(STORAGE_FINGERPRINT_KEY, indexedDBFingerprint) // Save to LocalStorage
      return decryptedId
    }
  }

  // Step 4: Generate new Fingerprint if not found
  const fp = await FingerprintJS.load()
  const result = await fp.get()

  if (result.confidence?.score >= 0) {
    const newFingerprint = result.visitorId
    // console.log(`data generated from fingerprint ${newFingerprint}`)
    const encryptedId = encrypt(newFingerprint)

    // console.log(`setting Data in Cookies ${encryptedId}`)
    // Save to all storage types
    Cookies.set(COOKIE_FINGERPRINT_KEY, encryptedId)
    // console.log(`setting Data in LocalStorage ${encryptedId}`)
    localStorage.setItem(STORAGE_FINGERPRINT_KEY, encryptedId)
    // console.log(`setting Data in IndexedDB ${encryptedId}`)
    await set(INDEX_FINGERPRINT_KEY, encryptedId)

    return newFingerprint
  }

  return null // Instead of throwing an error
}

// Custom Hook using State
export const useFingerprint = (): string | null => {
  const [fingerprintId, setFingerprintId] = useState<string | null>(null)

  useEffect(() => {
    getFingerprint()
      .then(setFingerprintId)
      .catch(() => setFingerprintId(null))
  }, [])

  return fingerprintId
}
