import Link from 'next/link'
import React, { useMemo } from 'react'
import { TrendingTopicCard, TrendingTopicCardProps } from 'src/components/TrendingTopicCard/TrendingTopicCard'
import { IPremiumAudienceItemFragment, useTrendingContentsQuery } from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface TrendingTopicsProps {
  audience: IPremiumAudienceItemFragment
  onClickWhenDisabled: () => void
}

export const TrendingTopics: React.FC<TrendingTopicsProps> = ({ audience, onClickWhenDisabled }) => {
  const { isPremium } = useAuthenticated()
  const name = audience.attributes!.name!

  const { data } = useTrendingContentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { audience: name },
  })

  const contents = useMemo<TrendingTopicCardProps[]>(
    () =>
      (data?.trendingContents || []).map((content) => {
        const item: TrendingTopicCardProps = {
          title: content.attributes?.title || '',
          snippet: content.attributes?.snippet || '',
          writer: content.attributes!.writer!,
          url: `/contents/${content.attributes?.slug}/`,
        }
        return item
      }),
    [data],
  )

  return (
    <div className="w-full px-3 large-lg:px-0 min-h-[20.75rem] bg-clouds pb-6" key={name}>
      <div className="!max-w-[1100px] flex flex-col justify-start mx-auto">
        <span className="font-secondary font-bold text-[1.5rem] text-black text-center md:text-left md:mr-10">
          Trending Topics for {name}
        </span>
        <div className="grid md:grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-5 mt-[1rem]">
          {contents?.map((content, idx) => <TrendingTopicCard key={idx} {...content} />) || []}
        </div>
        {isPremium ? (
          <Link
            href={`/contents/?audiences=${audience.id!}&contentType=KnowHow`}
            className="text-center font-secondary font-bold text-[1rem] text-textdark underline cursor-pointer"
          >
            View All
          </Link>
        ) : (
          <button
            className="text-center font-secondary font-bold text-[1rem] text-textdark underline cursor-pointer"
            onClick={onClickWhenDisabled}
          >
            View All
          </button>
        )}
      </div>
    </div>
  )
}
