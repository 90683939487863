import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import cn from 'classnames'
import { decode } from 'html-entities'
import { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SITE_URL } from 'src/config/config'
import {
  IAskAILogRatingInput,
  IEnum_Content_Contenttype,
  useUpdateAskAILogRatingMutation,
} from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { showToast } from 'src/store/actions/toastAction'

import { PremiumContentIcon } from './PremiumContentIcon/PremiumContentIcon'

// Utility function to decode HTML entities
function decodeHtmlEntities(text: string): string {
  return decode(text)
}

// RatingButton Component
interface RatingButtonProps {
  rating: number
  onRatingChange: (newRating: number) => void
}

const RatingButton: React.FC<RatingButtonProps> = ({ rating, onRatingChange }) => {
  const maxRating = 5
  const tooltips = ['Poor', 'Fair', 'Average', 'Good', 'Excellent']

  return (
    <div className="flex items-center gap-2">
      {Array.from({ length: maxRating }).map((_, index) => {
        const buttonRating = index + 1
        return (
          <div key={buttonRating} className="relative group">
            <div className="absolute bottom-14 left-1/2 transform -translate-x-1/2 scale-0 rounded bg-black text-white text-xs py-1 px-2 shadow-md group-hover:scale-100 transition duration-300 ease-in-out">
              {tooltips[index]}
            </div>
            <button
              className={`w-8 h-8 text-sm border-2 rounded transition-colors ${
                rating && buttonRating <= rating
                  ? 'bg-blue-700 text-white border-white'
                  : 'bg-blue-800 text-white border-blue-500 hover:bg-blue-600 hover:text-white'
              }`}
              onClick={() => onRatingChange(buttonRating)}
            >
              {buttonRating}
            </button>
          </div>
        )
      })}
    </div>
  )
}

// Interfaces
interface SmartSearchSources {
  description?: string | null
  logo?: string | null
  sourceUrl?: string | null
  title?: string | null
  contentType?: IEnum_Content_Contenttype | null
}

interface LoadingAnimationHrProps {
  className?: string
  result: string
  sources: SmartSearchSources[]
  id?: string
  identicalId?: number | null
}

interface CardComponentProps {
  title?: string
  description?: string
  imgUrl?: string
  linkTitle?: string
  linkUrl?: string
  type?: string
  shimRed: boolean
}

// CardComponent for displaying sources
const CardComponent: React.FC<CardComponentProps> = ({
  title = '',
  description = '',
  imgUrl = '',
  linkTitle = '',
  linkUrl = '#',
  type = '',
  shimRed = true,
}) => {
  const imgSrc = imgUrl || ''
  const [showProgress, setShowProgress] = useState(shimRed)
  const [descriptionText, setDescriptionText] = useState('')
  const [titleText, setTitleText] = useState('')
  let classProgess = showProgress ? 'shim-red' : ''
  let bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  useEffect(() => {
    const descText = description.replace(/(<([^>]+)>)/gi, '')
    const subString = descText.substring(0, 95)
    const titleDesc = linkTitle.replace(/(<([^>]+)>)/gi, '')
    const titleD = titleDesc.substring(0, 29)
    setDescriptionText(descText.length > subString.length ? subString + '...' : subString)
    setTitleText(titleDesc.length > titleD.length ? titleD + '...' : titleD)
    setShowProgress(shimRed)
    classProgess = showProgress ? 'shim-red' : ''
    bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
  }, [shimRed, description, linkTitle])

  const getContentType = () => {
    switch (type) {
      case IEnum_Content_Contenttype.LandscapeArticle:
        return IEnum_Content_Contenttype.LandscapeArticle
      case IEnum_Content_Contenttype.EvaluationFramework:
        return IEnum_Content_Contenttype.EvaluationFramework
      case IEnum_Content_Contenttype.RequirementsTemplate:
        return IEnum_Content_Contenttype.RequirementsTemplate
      case IEnum_Content_Contenttype.BusinessCase:
        return IEnum_Content_Contenttype.BusinessCase
      case IEnum_Content_Contenttype.CompetitiveAnalysis:
        return IEnum_Content_Contenttype.CompetitiveAnalysis
      case IEnum_Content_Contenttype.PilotGuide:
        return IEnum_Content_Contenttype.PilotGuide
      case IEnum_Content_Contenttype.ThoughtLeadership:
        return IEnum_Content_Contenttype.ThoughtLeadership
      case IEnum_Content_Contenttype.Blog:
        return IEnum_Content_Contenttype.Blog
      case IEnum_Content_Contenttype.VendorResource:
        return IEnum_Content_Contenttype.VendorResource
      case IEnum_Content_Contenttype.IndustryAnalysis:
        return IEnum_Content_Contenttype.IndustryAnalysis
      case IEnum_Content_Contenttype.BuyersGuide:
        return IEnum_Content_Contenttype.BuyersGuide
      default:
        return ''
    }
  }

  const contentType = getContentType()

  const shouldShowDefaultImage =
    contentType != ''
      ? contentType &&
        [
          IEnum_Content_Contenttype.LandscapeArticle,
          IEnum_Content_Contenttype.EvaluationFramework,
          IEnum_Content_Contenttype.RequirementsTemplate,
          IEnum_Content_Contenttype.BusinessCase,
          IEnum_Content_Contenttype.CompetitiveAnalysis,
          IEnum_Content_Contenttype.PilotGuide,
          IEnum_Content_Contenttype.ThoughtLeadership,
          IEnum_Content_Contenttype.Blog,
          IEnum_Content_Contenttype.VendorResource,
          IEnum_Content_Contenttype.IndustryAnalysis,
          IEnum_Content_Contenttype.BuyersGuide,
        ].includes(contentType)
      : false

  const logoContent = (
    <>
      {imgUrl != '' ? (
        <Image
          className="bg-white rounded-[.5rem] drop-shadow-md flex items-center justify-center w-[35px] h-[25px] min-w-[35px] min-h-[25px]"
          src={imgUrl}
          alt={title}
          width={20}
          height={20}
          priority
        />
      ) : shouldShowDefaultImage && contentType ? (
        <PremiumContentIcon contentType={contentType} size="sm" defaultImage={imgUrl} />
      ) : (
        <span className="relative w-1/4 h-[24px] bg-white rounded-[1rem] mr-1">
          <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}></span>
        </span>
      )}
    </>
  )

  return (
    <div className={bg + ' items-center rounded-lg w-64 overflow-hidden mb-4 ml-2 min-w-250'}>
      <Link
        href={linkUrl}
        className="inline-flex items-center justify-center text-base"
        rel="noopener noreferrer"
        title={linkTitle}
        target="_blank"
      >
        <div className="p-2 grid justify-center">
          <p className="w-[232px] h-[28px] flex flex-row text-xs leading-6">
            {imgSrc && (
              <Link className="text-left mt-2" href={imgSrc}>
                {logoContent}
              </Link>
            )}
            {!imgSrc && logoContent}
            <span
              className={
                'relative w-full h-[24px] truncate pl-2 text-blue-500 rounded-lg bg-gray-50 hover:text-glue-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white hover:underline'
              }
            >
              <span
                className={
                  'text-ellipsis overflow-hidden ... w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess
                }
              >
                {titleText}
              </span>
            </span>
          </p>
          <p className="relative w-[232px] h-[80px] bg-white mb-2 text-xs leading-6">
            <span className={'w-[232px] absolute top-0 h-[80px] ' + classProgess}>{descriptionText}</span>
          </p>
        </div>
      </Link>
    </div>
  )
}

function decodeJWT(token: any) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}

// Main LoadingAnimationHr Component
export const LoadingAnimationHr: NextPage<LoadingAnimationHrProps> = ({
  className,
  result,
  sources,
  id,
  identicalId,
}) => {
  const [resultHeight, setResultHeight] = useState('h-[100px]')
  const [classProgess, setClassProgess] = useState('shim-red')
  const [showRating, setShowRating] = useState(result ? true : false)
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)
  const [selectedRating, setSelectedRating] = useState<number | null>(null)
  const [feedback, setFeedback] = useState('')
  const [updateRating] = useUpdateAskAILogRatingMutation()
  const { session } = useAuthenticated()
  const userId = session?.user.id
  const token = session?.user?.idToken
  const userEmail = decodeJWT(token)?.email
  const isLegalTechTeam = userEmail?.endsWith('@impressico.com') || userEmail?.endsWith('@legaltechnologyhub.com')
  const dispatch: Dispatch<any> = useDispatch()
  let bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  // Update UI based on result availability
  useEffect(() => {
    setClassProgess('shim-red')
    setResultHeight('h-[100px]')
    bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
    if (result) {
      setClassProgess('')
      setResultHeight('h-full')
      setShowRating(true)
    }
  }, [result])

  // Format result text with basic markdown-like syntax
  const formattedText = result
    .replace(/\n/g, '<br>')
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/### (.*?)\n/g, '<h3>$1</h3>')
    .replace(/## (.*?)\n/g, '<h2>$1</h2>')
    .replace(/# (.*?)\n/g, '<h1>$1</h1>')
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    .replace(/###/g, '')
    .replace(/#/g, '')

  // Submit rating and feedback to the backend
  const submitRating = async (rating: number, feedback: string) => {
    try {
      const req: IAskAILogRatingInput = {
        rating,
        id,
        userID: userId,
        identicalId: identicalId || null,
        feedback,
        email: userEmail || '',
      }

      const res = await updateRating({ variables: { data: req } })
      const success = res?.data?.updateAskAILogRating?.success
      const message = res?.data?.updateAskAILogRating?.message || 'Rating updated successfully'

      setShowRating(!success)
      dispatch(showToast(message, success ? 'success' : 'error'))
    } catch (error) {
      dispatch(showToast('Failed to update rating', 'error'))
    }
  }

  // Handle rating selection
  const handleRatingChange = (newRating: number) => {
    if (isLegalTechTeam) {
      setSelectedRating(newRating)
      setShowFeedbackDialog(true)
    } else {
      submitRating(newRating, '')
    }
  }

  return (
    <div className={cn('flex flex-col items-center')}>
      {/* Result Display */}
      <div className="flex flex-row space-x-4 mb-4">
        <div className={bg + ' p-4 flex flex-row items-center rounded-lg overflow-hidden'}>
          <p
            className={'w-[784px] bg-white rounded-[1rem] p-2 mb-2 ' + resultHeight + ' ' + classProgess}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </div>
      </div>

      {/* Sources Display */}
      <div className={cn(className, 'justify-between mb-4')}>
        {sources.length > 0 ? (
          <>
            {sources.map((item: any) => (
              <CardComponent
                title={item?.title}
                linkUrl={item?.sourceUrl}
                linkTitle={item?.title}
                description={decodeHtmlEntities(item?.description || '')}
                imgUrl={item?.logo || `${SITE_URL}/vendor-logo.svg`}
                type={item?.contentType}
                shimRed={item ? false : true}
              />
            ))}
          </>
        ) : result && sources.length == 0 ? (
          ''
        ) : (
          <>
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
          </>
        )}
      </div>

      {/* Rating Section */}
      {showRating && (
        <div className="mb-4 flex items-center space-x-4">
          <h3 className="text-xl font-semibold text-white">Rate this answer:</h3>
          <RatingButton rating={0} onRatingChange={handleRatingChange} />
        </div>
      )}

      {/* Feedback Dialog for Legal Tech Hub Team */}
      <Dialog
        open={showFeedbackDialog}
        onClose={() => setShowFeedbackDialog(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: { width: '80vw', height: '70vh', maxWidth: 'none', p: 2 },
        }}
      >
        <DialogTitle>Provide Feedback</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          {' '}
          {/* Removed bottom padding */}
          <TextField
            autoFocus
            margin="dense"
            label="Feedback"
            type="text"
            fullWidth
            multiline
            rows={10}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', p: 3 }}>
          <Button onClick={() => setShowFeedbackDialog(false)} variant="outlined" sx={{ px: 3, py: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (selectedRating !== null) {
                submitRating(selectedRating, feedback)
              }
              setShowFeedbackDialog(false)
              setFeedback('')
            }}
            variant="contained"
            color="primary"
            sx={{ px: 4, py: 1, fontWeight: 'bold' }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
