/**
 * Get initials from the name
 * @param name string or firstName + lastName
 * @param size one letter/two letters initials
 */
export const getInitials = (
  name?: string | { firstName?: string | null; lastName?: string | null } | null,
  size: 1 | 2 = 2,
): string | undefined | null => {
  if (!name) {
    return name
  }
  let segments: string[] = []
  if (typeof name === 'string') {
    segments = name
      .split(' ')
      .map((segment) => segment.trim())
      .filter((segment) => !!segment)
    segments.splice(1, segments.length - 2)
  } else {
    segments = [name.firstName, name.lastName]
      .map((segment) => (segment ? segment.trim() : segment))
      .filter((segment): segment is string => !!segment)
  }

  return segments
    .map((segment) => segment[0])
    .slice(0, size)
    .join('')
}
