import {
  IContentItemFragment,
  IContentListItemFragment,
  ISolutionVendorResourceExternalFragment,
} from 'src/graphql/generated/hooks'

export type ContentDataFragment =
  | IContentItemFragment
  | IContentListItemFragment
  | ISolutionVendorResourceExternalFragment

export const isContentListItemFragment = (
  item: IContentItemFragment | IContentListItemFragment | ISolutionVendorResourceExternalFragment,
): item is IContentListItemFragment => 'attributes' in item

export const isContentItem = (
  item: IContentItemFragment | IContentListItemFragment | ISolutionVendorResourceExternalFragment,
): item is IContentItemFragment => 'writer' in item

export const isSolutionVendorResourceExternalFragment = (
  item: IContentItemFragment | IContentListItemFragment | ISolutionVendorResourceExternalFragment,
): item is ISolutionVendorResourceExternalFragment => !isContentListItemFragment(item) && !isContentItem(item)
