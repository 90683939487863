//import IonIcon from '@reacticons/ionicons'
/* import 'swiper/swiper-bundle.css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'*/
import 'swiper/css/autoplay'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import cn from 'classnames'
import { omitBy } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { IContentItem, useSearchContentsLazyQuery } from 'src/graphql/generated/hooks'
// import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ArticleCard } from './ArticleCard'

export interface PremiumCategoriesProps {
  isVendor: boolean
}

export const ArticleCarsoule: React.FC<PremiumCategoriesProps> = memo(({ isVendor }) => {
  const [loadContents, { data: contentsData }] = useSearchContentsLazyQuery()
  const [articleItems, setArticleItems] = useState<IContentItem[]>([])
  const [openTooltipCardId, setOpenTooltipCardId] = useState<string | null>(null)
  const swiperRef = useRef<any>(null)
  const [subjectMatterPopup, setSubjectMatterPopup] = useState(0)
  //const { isVendor, status } = useAuthenticated()

  useEffect(() => {
    ;(async () => {
      const query = {
        contentType: 'Blog',
        limit: 3,
        sort: 'Category_Chronologically',
        articleCarsouleFor: isVendor ? 'vendor' : 'user',
      }
      loadContents({ variables: { query: omitBy(query, (value: any) => !value) } })
    })()
  }, [isVendor])

  useEffect(() => {
    if (contentsData) {
      const posts: IContentItem[] =
        contentsData.contentResults?.data?.contents?.filter((content): content is IContentItem => !!content) || []
      setArticleItems(posts)
    }
  }, [contentsData])

  const carouselSlideChange = (index: number) => {
    const randomNum = Math.floor(Math.random() * 90000) + 10000 + index
    setSubjectMatterPopup(randomNum)
  }

  return articleItems.length > 0 ? (
    <div className={cn('w-full items-center flex bg-[#F1F3F5]')}>
      <div className={cn('article-conatiner pt-[40px] pb-[56px]')}>
        <div className="!max-w-[1080px] flex flex-col justify-start mx-auto">
          <span className={`font-primary font-bold text-[15px] leading=[18px] px-[5px] text-[#011D58] mb-[16px]`}>
            FEATURED ARTICLES
          </span>
        </div>
        <div className=" flex flex-col justify-start">
          <div
            className="flex justify-center"
            onMouseEnter={() => swiperRef.current?.autoplay.stop()}
            onMouseLeave={() => swiperRef.current?.autoplay.start()}
          >
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              }}
              centeredSlides={true}
              spaceBetween={0}
              slidesPerView={1.46}
              className="mySwiper"
              onSwiper={(swiper) => {
                swiperRef.current = swiper
              }}
              speed={1000}
              onSlideChange={(swiper) => carouselSlideChange(swiper.activeIndex)}
            >
              {articleItems.map((item) => (
                <SwiperSlide key={item.id}>
                  <ArticleCard
                    key={item.id}
                    item={item}
                    openTooltipCardId={openTooltipCardId}
                    setOpenTooltipCardId={setOpenTooltipCardId}
                    subjectMatterPopup={subjectMatterPopup}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
})
