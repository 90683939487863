import React, { useEffect, useMemo, useState } from 'react'

import TooltipModel from './TooltipModel'

interface InsightCardTagsProps {
  cardId: string
  className?: string
  tagItems?: string | null
  openTooltipCardId?: string | null
  setOpenTooltipCardId: (id: string | null) => void
  containerSize?: number
  subjectMatterPopup?: number
}

export const InsightCardTags: React.FC<InsightCardTagsProps> = ({
  cardId,
  tagItems,
  openTooltipCardId,
  setOpenTooltipCardId,
  containerSize = 292,
  subjectMatterPopup,
}) => {
  // eslint-disable-next-line no-console
  /* let tagArray = tagItems?.split(',').map((item: string) => item.trim()) || []
  if (tagArray && tagArray.length > 0) {
    tagArray = tagArray?.sort((a: string, b: string) => a.length - b.length)
  } */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tags, setTags] = useState<string[]>([])
  const [showTag, setShowTag] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openCardId, setOpenCardID] = useState(cardId)
  const [isOpen, setIsOpen] = useState(false)

  const containerWidth = containerSize //292 // Fixed width of the container in px
  const characterWidth = 7.5 // Average width of one character in px
  const badgePadding = 10 // Total horizontal padding (px-2 = 8px * 2)
  const badgeSpacing = 8 // 8 // No extra spacing assumed between badges

  // Function to calculate how many badges fit dynamically
  const badgesToDisplay = useMemo(() => {
    let availableWidth = containerWidth
    const displayedBadges: string[] = []

    for (const tag of tags) {
      const textWidth = tag.length * characterWidth
      const badgeWidth = textWidth + badgePadding + badgeSpacing
      if (availableWidth >= badgeWidth) {
        displayedBadges.push(tag)
        availableWidth -= badgeWidth
      } else {
        break // No more badges can fit
      }
    }
    return displayedBadges
  }, [tags])

  useEffect(() => {
    if (tagItems) {
      let tagArray = tagItems.split(',').map((item) => item.trim())
      tagArray = tagArray.sort((a, b) => a.length - b.length)
      setTags(tagArray)
    }
  }, [tagItems])

  const handleTooltipToggle = () => {
    if (openTooltipCardId === cardId) {
      setOpenTooltipCardId(null) // Close the tooltip if already open
    } else {
      setOpenTooltipCardId(cardId) // Open the current tooltip
    }
    setShowTag(!showTag)
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setOpenTooltipCardId(null)
  }, [subjectMatterPopup])

  interface ListBadgesProps {
    tags?: string[]
  }
  const ListBadges: React.FC<ListBadgesProps> = ({ tags }) => {
    if (tags && tags.length > 1 && tags.length > badgesToDisplay.length) {
      return (
        <button className="px-1 py-1  text-blue-600 text-xs font-medium rounded" onClick={handleTooltipToggle}>
          {openTooltipCardId !== cardId ? `+${tags.length - badgesToDisplay.length} more` : '- less'}
        </button>
      )
    }
    return ''
  }

  useEffect(() => {
    setOpenTooltipCardId(null)
    setOpenCardID('0')
  }, [subjectMatterPopup])

  return (
    <div className={`badges_wrapper ${showTag ? 'show' : ''}`}>
      {badgesToDisplay.map((item, index) => (
        <span key={index} className="badges bg-gray-200 px-2 py-1 rounded-full  font-semibold">
          {item}
        </span>
      ))}

      <div className="more_btn relative">
        {openTooltipCardId === cardId && <TooltipModel tags={tags.filter((item) => !badgesToDisplay.includes(item))} />}
        {openCardId && <ListBadges tags={tags} />}
      </div>
    </div>
  )
}
