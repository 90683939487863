import Tooltip from '@mui/material/Tooltip'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import { useState } from 'react'
import { THEME } from 'src/config/config'

import styles from './smartsearch.module.css'

export interface SmartSearchProps {
  className?: string
  fetchdata: (inputValue: string) => void
}

export const SmartSearchBox: React.FC<SmartSearchProps> = ({ className = '', fetchdata, ...props }) => {
  const [searchText, setSearchText] = useState('')
  const [searchFocused, setSearchFocused] = useState(true)
  const onFocus = () => {
    setSearchFocused(true)
  }

  const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value ? e.target.value : ''
    setSearchText(text)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handelSearch()
    }
  }

  const handelSearch = () => {
    if (searchText === '') {
      alert('Please enter your search query.')
      return false
    }
    fetchdata(searchText)
  }

  return (
    <div
      className={cn(
        'relative',
        className,
        searchFocused
          ? 'sm:w-[45rem] w-[48rem] transition: width 5s, height 5s'
          : 'sm:w-[35rem] w-[48rem] transition: width 5s, height 5s',
      )}
      onFocus={onFocus}
    >
      <div
        className={cn(
          'z-20 relative rounded-[1.875rem] border-2 border-turquoise bg-white border-solid flex flex-row items-center pl-[1.3125rem] pr-[0.75rem] h-[60px]',
          {
            'shadow-md': searchFocused,
          },
          { 'text-darkgray2': THEME === 'ilta' },
          { 'text-textdark': THEME === 'legaltech' },
        )}
        {...props}
      >
        <input
          autoFocus
          type="text"
          className={cn(
            styles.searchInput,
            'focus:outline-none grow text-[14px] mr-[14px] leading-[42px]',
            { 'placeholder-darkgray2 font-secondary font-normal': THEME === 'ilta' },
            { 'placeholder-textdark font-primary font-medium': THEME === 'legaltech' },
          )}
          placeholder="Please enter your question here"
          onChange={onTextChanged}
          onKeyUp={handleKeyPress}
        />
        {searchText === '' ? (
          <button
            title={!searchText ? 'Please enter your search query' : ''}
            className={'pt-10 pb-10 ps-5 pe-5' + !searchText ? '' : 'text-turquoise'}
          >
            <Tooltip title="Search all results" placement="right">
              <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
                <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
              </div>
            </Tooltip>
          </button>
        ) : (
          <button
            disabled={!searchText}
            onClick={handelSearch}
            title={!searchText ? 'Please enter your search query' : ''}
            className={'pt-10 pb-10 ps-5 pe-5' + !searchText ? '' : 'text-turquoise'}
          >
            <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
              <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
            </div>
          </button>
        )}
      </div>
    </div>
  )
}
